<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  			<v-card-title primary-title>
	  		    <span class="text-subtitle-1">Videos 14vo </span>
	  		    <v-spacer></v-spacer>

	  		    <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( videos  , 'VIDEO_14VO')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
	  		  </v-card-title>

          <v-card-text>
            <v-row justify="space-between">
              <v-col cols="12" md="3" lg="3">
                <v-select
                  :items="['TEACHER','ALUMNO']"
                  v-model="tipo"
                  label="TIPO"
                  clearable
                  filled
                  dense
                  hide-details
                  single-line
                ></v-select>
              </v-col>


              <v-col cols="12" md="4" lg="4">
                <v-text-field
                  label="Buscar"
                  dense
                  filled
                  hide-details
                  single-line
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
  				  <v-data-table
  				    :headers="headers"
  				    :items="filterVideos"
  				    class="elevation-0"
              :search="search"
  				  >

  				    <template v-slot:item.escuela="{ item }">
                <v-chip small v-if="item.escuela==1" color="#2EDAD5"> INBI </v-chip>
                <v-chip small v-else color="#000D4A" dark> FAST </v-chip>
              </template>

  				    <template v-slot:item.actions="{ item }">
                <v-chip small @click="editItem(item)" color="primary" dark> Ver </v-chip>
                <v-chip v-if="item.roll == 'TEACHER'" @click="deleteItem(item)" small color="error" dark class="ms-5"> Eliminar </v-chip>
  				    </template>

  				    <template v-slot:no-data>
  				      <v-btn
  				        color="primary"
  				        @click="initialize"
  				        small
  				      >
  				        Actualizar
  				      </v-btn>
  				    </template>
  				  </v-data-table>
              
          </v-card-text>

				</v-card>
		  </v-col>
	  </v-row>

	  <!-- Agregar un nuevo registro -->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
    >
      <v-card max-heigth="400">
        <v-card-title>
          <span class="text-subtitle-1"> Visualizar video </span>
        </v-card-title>

        <v-card-text>
        	<span class="black--text">{{ alumno.alumno }} <br/> {{ alumno.usuario }}</span><br/>
          <video id="video" 
	          :src="urlDirecta"
	          frameborder="0" 
	          allowfullscreen
	          controls
	          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
	          style="width: 100%; max-height: 400px; background: #FFFFFF;" allowtransparency="true">
	        </video>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="close"
          >
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ID', sortable: false, value: 'idvideos_alumno', },
        { text: 'Alumno'     , value: 'alumno' },
        { text: 'Escuela'    , value: 'escuela' },
        { text: 'Roll'       , value: 'roll' },
        { text: 'Teléfono'   , value: 'telefono' },
        { text: 'Fecha'      , value: 'fecha_creacion' },
        { text: 'Actions'    , value: 'actions', sortable: false },
      ],
      videos: [],
      editedIndex: -1,

      urlFast:'https://lmsfast.club/api-movil/lms-videos-fast/',
      urlInbi:'https://lmsinbi.club/api-movil/lms-videos-inbi/',
      urlDirecta:'',
      alumno:{},
      search:'',

      tipo: null,
    }),

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    computed:{
      
      filterVideos( ){
        if( this.tipo ){
          return this.videos.filter( el => el.roll == this.tipo )
        }else{
          return this.videos
        }
      }
    
    },

    async created () {
      await this.initialize()
    },

    methods: {
      initialize () {
        this.videos = []
        this.cargar = true
        return this.$http.get('alumnos.videos').then(response=>{
        	this.videos = response.data
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {

      	this.alumno = item

       	if( item.escuela == 2 )
       		this.urlDirecta = this.urlFast + item.nombre_video
       	else
       		this.urlDirecta = this.urlInbi + item.nombre_video

        this.dialog = true
      },

      deleteItem (item) {
        this.cargar = true;
        this.$http.delete('alumnos.eliminarvideo/'+item.idvideos_alumno+'/'+item.nombre_video+'/'+item.escuela).then(response=>{
          this.initialize()
          this.validarError(response.data)
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
    },
  }
  /*454*/
</script>